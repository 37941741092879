import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-datatable/styles.css';
import '@mantinex/mantine-header/styles.css';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { AuthProvider } from './providers/auth/AuthProvider';
import { GoogleAuthProvider } from './providers/auth/GoogleAuthProvider';
import { Router } from './routes/Router';
import { theme } from './theme';

export default function App() {
    return (
        <AuthProvider>
            <GoogleAuthProvider>
                <MantineProvider theme={theme}>
                    <ModalsProvider>
                        <Notifications />
                        <Router />
                    </ModalsProvider>
                </MantineProvider>
            </GoogleAuthProvider>
        </AuthProvider>
    );
}
