import React from 'react';
import { Button, Group, Modal } from '@mantine/core';

type ConfirmationModalProps = {
    title: string;
    message: string;
    opened: boolean;
    onClose: () => void;
    onConfirm?: () => Promise<void>;
    confirmButtonLabel?: string;
    confirmButtonProps?: React.ComponentProps<typeof Button>;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, message, opened, onClose, onConfirm, confirmButtonLabel, confirmButtonProps }) => {
    const [confirming, setConfirming] = React.useState(false);

    return (
        <Modal centered title={title} opened={opened} onClose={onClose}>
            {message}
            <Group mt="lg" justify="flex-end">
                <Button onClick={() => onClose()} variant="default">
                    Cancel
                </Button>
                <Button
                    {...confirmButtonProps}
                    loading={confirming}
                    onClick={async () => {
                        if (onConfirm) {
                            setConfirming(true);
                            await onConfirm().finally(() => {
                                setConfirming(false);
                            });
                        }
                    }}
                >
                    {confirmButtonLabel || 'Confirm'}
                </Button>
            </Group>
        </Modal>
    );
};
