import React from 'react';
import { NativeSelect, TextInput } from '@mantine/core';
import { currencyMap } from '@/types/currency';

const data = Object.entries(currencyMap).map(([code, flag]) => ({ value: code, label: `${flag} ${code}` }));

type CurrencyInputProps = {
    label?: string;
    value?: { currencyCode?: string; amount?: number };
    onCurrencyChange?: (currencyCode: string) => void;
    onAmountChange?: (amount: number) => void;
    required?: boolean;
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ label, value, onCurrencyChange, onAmountChange, required }) => {
    const select = (
        <NativeSelect
            data={data}
            value={value?.currencyCode || 'HKD'}
            onChange={(event) => onCurrencyChange && onCurrencyChange(event.currentTarget.value)}
            rightSectionWidth={28}
            styles={{
                input: {
                    fontWeight: 500,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    width: 96,
                    marginRight: -2,
                },
            }}
        />
    );

    return (
        <TextInput
            type="number"
            value={value?.amount}
            placeholder="100"
            label={label}
            rightSection={select}
            rightSectionWidth={92}
            onChange={(event) => {
                onAmountChange && onAmountChange(Number(event.currentTarget.value));
            }}
            required={required}
        />
    );
};
