import { Button, Container, Stack } from '@mantine/core';
import { capture } from '@/handlers/error';

export function AnalyticsPlayground() {
    return (
        <Container>
            <Stack>
                <Button
                    onClick={() => {
                        throw new Error('This is your first error!');
                    }}
                >
                    Break the world
                </Button>
                <Button
                    onClick={() => {
                        capture(new Error('Custom error message'));
                    }}
                >
                    Capture error
                </Button>
            </Stack>
        </Container>
    );
}
