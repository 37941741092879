import { createContext, PropsWithChildren, useCallback, useContext } from 'react';
import { GoogleAuthProvider as FirebaseGoogleAuthProvider, getAuth, signInWithPopup, UserCredential } from 'firebase/auth';

interface GoogleAuthContextType {
    login: () => Promise<UserCredential>;
}

const GoogleAuthContext = createContext<GoogleAuthContextType | undefined>(undefined);

export const GoogleAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const login = useCallback(async () => {
        const provider = new FirebaseGoogleAuthProvider();
        const auth = getAuth();
        auth.useDeviceLanguage();

        return signInWithPopup(auth, provider);
    }, []);

    return (
        <GoogleAuthContext.Provider
            value={{
                login,
            }}
        >
            {children}
        </GoogleAuthContext.Provider>
    );
};

export const useGoogleAuth = (): GoogleAuthContextType => {
    const context = useContext(GoogleAuthContext);
    if (!context) {
        throw new Error('useGoogleAuth must be used within an GoogleAuthProvider');
    }

    return context;
};
