import { Link } from 'react-router-dom';
import { Box, Group, rem, ThemeIcon } from '@mantine/core';
import classes from './NavbarLink.module.css';

export interface NavbarLinkProps {
    icon: React.FC<any>;
    label: string;
    link: { label: string; to: string };
    onClick?: () => void;
}

export function NavbarLink({ icon: Icon, label, link, onClick }: NavbarLinkProps) {
    return (
        <Link className={`${classes.control} ${classes.link}`} key={link.label} to={link.to} onClick={() => onClick && onClick()}>
            <Group justify="space-between" gap={0}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <ThemeIcon variant="light" size={30}>
                        <Icon style={{ width: rem(18), height: rem(18) }} />
                    </ThemeIcon>
                    <Box ml="md">{label}</Box>
                </Box>
            </Group>
        </Link>
    );
}
