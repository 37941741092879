import { useNavigate } from 'react-router-dom';
import { Container } from '@mantine/core';
import { AuthenticationForm } from '@/components/AuthenticationForm/AuthenticationForm';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { useGoogleAuth } from '@/providers/auth/GoogleAuthProvider';
import classes from './Welcome.page.module.css';

const WelcomePage: React.FC = () => {
    const { login, signUp } = useAuth();
    const { login: loginWithGoogle } = useGoogleAuth();
    const navigate = useNavigate();

    return (
        <Container p="md" style={{ height: '100dvh', alignContent: 'center' }}>
            <AuthenticationForm
                className={classes.form}
                onSubmit={async (form) => {
                    try {
                        if (form.type === 'login') {
                            await login(form.email, form.password);
                        } else {
                            await signUp(form.email, form.password, form.name);
                        }

                        navigate('/expense/upload');
                    } catch (error) {
                        capture(error);
                    }
                }}
                onGoogleLogin={async () => {
                    try {
                        await loginWithGoogle();
                        navigate('/expense/upload');
                    } catch (error) {
                        capture(error);
                    }
                }}
            />
        </Container>
    );
};
export default WelcomePage;
