import { useEffect, useState } from 'react';
import { IconLogout, IconUser } from '@tabler/icons-react';
import { doc, getDoc } from 'firebase/firestore';
import { Box, Flex, Menu, rem, ScrollArea, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { db } from '@/firebase';
import { capture } from '@/handlers/error';
import { useCredit } from '@/hooks/useCredit';
import { useAuth } from '@/providers/auth/AuthProvider';
import { NavbarLink, NavbarLinkProps } from '../NavbarLink/NavbarLink';
import { LinksGroup, LinksGroupProps } from '../NavbarLinksGroup/NavbarLinksGroup';
import { RoleBadge } from '../RoleBadge/RoleBadge';
import { UserButton, UserButtonProps } from '../UserButton/UserButton';
import classes from './NavbarNested.module.css';

function isNavbarLink(item: unknown): item is NavbarLinkProps {
    return (item as NavbarLinkProps).link !== undefined && (item as NavbarLinkProps).label !== undefined && (item as NavbarLinkProps).icon !== undefined;
}

export type NavbarNestedProps = {
    items: (LinksGroupProps | NavbarLinkProps)[];
    onLinkClick?: () => void;
} & UserButtonProps;

export function NavbarNested({ items, onLinkClick, user }: NavbarNestedProps) {
    const { logout } = useAuth();
    const { credit } = useCredit(user);
    const isMobile = useMediaQuery('(max-width: 50em)');
    const [menuOpened, setMenuOpened] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);
    const [organization, setOrganization] = useState<string | undefined>();

    useEffect(() => {
        if (user) {
            try {
                user.getIdTokenResult().then((idTokenResult) => {
                    if (Array.isArray(idTokenResult.claims.roles)) {
                        setRoles(idTokenResult.claims.roles);
                    }

                    getDoc(doc(db, 'organizations', `${idTokenResult.claims.org}`)).then((doc) => {
                        const name = doc.data()?.name;
                        if (name && typeof name === 'string') {
                            setOrganization(name);
                        } else {
                            setOrganization(`${idTokenResult.claims.org}`);
                        }
                    });
                });
            } catch (error) {
                capture(error);
            }
        }
    }, [user]);

    const links = items.map((item) => {
        if (isNavbarLink(item)) {
            return <NavbarLink {...item} key={item.label} onClick={onLinkClick} />;
        }

        return <LinksGroup {...item} key={item.label} onClick={onLinkClick} />;
    });

    return (
        <nav className={classes.navbar}>
            <ScrollArea className={classes.links}>
                <div className={classes.linksInner}>{links}</div>
            </ScrollArea>

            <div className={classes.footer}>
                <Menu shadow="md" width="200" position={isMobile ? 'top' : 'right-end'} withArrow arrowPosition="center" opened={menuOpened} onChange={setMenuOpened} trigger="click">
                    <Menu.Dropdown>
                        {organization && (
                            <>
                                <Menu.Label>Organization</Menu.Label>
                                <Box py={rem(6)} px="sm">
                                    <Text size="sm">{organization}</Text>
                                </Box>
                            </>
                        )}
                        <Menu.Label>Role</Menu.Label>
                        <Flex py={rem(6)} px="sm" columnGap={rem(4)} rowGap={rem(6)} wrap="wrap">
                            {roles.map((role) => {
                                return <RoleBadge key={role} role={role} />;
                            })}
                        </Flex>
                        {credit && (
                            <>
                                <Menu.Label>Credit</Menu.Label>
                                <Box py={rem(6)} px="sm">
                                    <Text size="sm">{-Math.abs(credit.consumption)}</Text>
                                </Box>
                            </>
                        )}
                        <Menu.Label>User</Menu.Label>
                        <Menu.Item leftSection={<IconUser style={{ width: rem(16), height: rem(18) }} stroke={1.5} />} disabled>
                            Account
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item leftSection={<IconLogout style={{ width: rem(16), height: rem(18) }} stroke={1.5} />} onClick={() => logout()}>
                            Sign out
                        </Menu.Item>
                    </Menu.Dropdown>
                    <Menu.Target>
                        <UserButton user={user} />
                    </Menu.Target>
                </Menu>
            </div>
        </nav>
    );
}
