import { useCallback, useEffect, useRef, useState } from 'react';
import { IconClick, IconEdit, IconEye, IconSend, IconTrash } from '@tabler/icons-react';
import { collection, deleteDoc, doc, endAt, getDocs, limit, orderBy, query, QueryDocumentSnapshot, serverTimestamp, startAfter, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { DataTable } from 'mantine-datatable';
import { ActionIcon, Anchor, Box, Breadcrumbs, Center, Group, Image, Modal, rem, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { ConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
import { CountingButton } from '@/components/CountingButton/CountingButton';
import { MissingIcon } from '@/components/MissingIcon/MissingIcon';
import { db, Firebase, storage } from '@/firebase';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Record } from '@/types/claim';
import { currencyMap } from '@/types/currency';
import { ClaimEditor } from '../ClaimEditor/ClaimEditor';
import classes from './ClaimTable.module.css';

const PAGE_SIZE = 50;

export function ClaimTable() {
    const { currentUser } = useAuth();

    // UI state
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [records, setRecords] = useState<Record[] | undefined>(undefined);
    const [showPreview, { open: openPreview, close: closePreview }] = useDisclosure(false);
    const [previewRecord, setPreviewRecord] = useState<Record | null>(null);
    const [selectedRecords, setSelectedRecords] = useState<Record[]>([]);
    const isMobile = useMediaQuery('(max-width: 50em)');
    const scrollViewportRef = useRef<HTMLDivElement>(null);
    const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);
    const [deleteRecord, setDeleteRecord] = useState<Record | null>(null);

    const refresh = useCallback(
        async (lastVisible?: QueryDocumentSnapshot) => {
            setLoading(true);

            const q = lastVisible
                ? query(collection(db, Firebase.Firestore.Collection.Claims), orderBy('creation', 'desc'), endAt(lastVisible), where('uid', '==', currentUser?.uid))
                : query(collection(db, Firebase.Firestore.Collection.Claims), orderBy('creation', 'desc'), limit(records?.length ?? PAGE_SIZE), where('uid', '==', currentUser?.uid));

            await getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords(result);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .catch((error) => capture(error))
                .finally(() => {
                    setLoading(false);
                });
        },
        [records, currentUser]
    );

    const loadMoreRecords = useCallback(
        (lastVisible?: QueryDocumentSnapshot) => {
            setLoading(true);

            const q = lastVisible
                ? query(collection(db, Firebase.Firestore.Collection.Claims), orderBy('creation', 'desc'), startAfter(lastVisible), limit(PAGE_SIZE), where('uid', '==', currentUser?.uid))
                : query(collection(db, Firebase.Firestore.Collection.Claims), orderBy('creation', 'desc'), limit(PAGE_SIZE), where('uid', '==', currentUser?.uid));

            getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords((r) => (r ? r.concat(result) : result));
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .catch((error) => capture(error))
                .finally(() => {
                    setLoading(false);
                });
        },
        [currentUser]
    );

    const setPreview = async (record: Record) => {
        try {
            if (record.url) {
                await getDownloadURL(ref(storage, record.url)).then((url) => {
                    setPreviewRecord({ ...record, url });
                    openPreview();
                });
            }
        } catch (error) {
            capture(error);
        }
    };

    useEffect(() => {
        setRecords(undefined);
        loadMoreRecords();
    }, [loadMoreRecords]);

    return (
        <Box display="flex" style={{ width: '100%', flexDirection: 'column' }}>
            <Box mb="sm" display="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumbs>
                    {[{ title: 'Expense' }, { title: 'Claim' }].map((item, index) => (
                        <Title order={3} key={index}>
                            {item.title}
                        </Title>
                    ))}
                </Breadcrumbs>
                <CountingButton
                    onClick={async () => {
                        if (currentUser) {
                            setSubmitting(true);
                            const org = await currentUser.getIdTokenResult().then((r) => r.claims.org);
                            const updates = selectedRecords.map((record) => {
                                const ref = doc(db, Firebase.Firestore.Collection.Claims, record.id);
                                return updateDoc(ref, { submission: { oid: org, email: currentUser.email, timestamp: serverTimestamp() } }).then(() => ({ id: record.id }));
                            });

                            await Promise.all(updates)
                                .then(async () => {
                                    await refresh(lastVisible);
                                    notifications.show({
                                        title: 'Submission',
                                        message: `${selectedRecords.length} record(s) is submitted`,
                                    });
                                })
                                .catch((error) => {
                                    capture(error);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }
                    }}
                    disabled={selectedRecords.length <= 0}
                    loading={submitting}
                    leftSection={`${selectedRecords.length}`}
                    rightSection={<IconSend style={{ width: rem(18) }} />}
                >
                    Submit
                </CountingButton>
            </Box>
            <Box style={{ position: 'relative', flex: 1, backgroundColor: 'yellow' }}>
                <Box style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <DataTable
                        withTableBorder={false}
                        striped
                        classNames={{
                            header: classes.header,
                        }}
                        highlightOnHover
                        columns={[
                            {
                                accessor: 'name',
                                render: (record) => {
                                    if (record.url) {
                                        return (
                                            <Anchor
                                                fz="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPreview(record);
                                                }}
                                            >
                                                {record.name}
                                            </Anchor>
                                        );
                                    }
                                    return record.name;
                                },
                            },
                            {
                                accessor: 'summary',
                                render: (record) => {
                                    return record.description || <MissingIcon />;
                                },
                            },
                            {
                                accessor: 'category',
                            },
                            {
                                accessor: 'currencyCode',
                                title: 'Currency',
                                render: ({ currencyCode }) => {
                                    const value = currencyCode && currencyMap[currencyCode] ? `${currencyMap[currencyCode]} ${currencyCode}` : currencyCode;

                                    return value || <MissingIcon />;
                                },
                            },
                            {
                                accessor: 'amount',
                                render: ({ amount }) =>
                                    amount ? (
                                        <Text c="teal" size="sm">
                                            {amount}
                                        </Text>
                                    ) : (
                                        <MissingIcon />
                                    ),
                            },
                            {
                                accessor: 'date',
                                noWrap: true,
                                render: ({ date }) => date || <MissingIcon />,
                            },
                            {
                                accessor: 'creation',
                                render: ({ creation }) => `${creation?.toDate().toLocaleString()}`,
                                noWrap: true,
                            },
                            {
                                accessor: 'submission.timestamp',
                                title: 'Submission',
                                render: (record) => (record.submission?.timestamp ? `${record.submission.timestamp.toDate().toLocaleString()}` : 'Pending'),
                                noWrap: true,
                            },
                            {
                                accessor: 'actions',
                                title: (
                                    <Center>
                                        <IconClick size={20} />
                                    </Center>
                                ),
                                textAlign: 'right',
                                render: (record) => (
                                    <Group gap={4} justify="right" wrap="nowrap">
                                        <ActionIcon
                                            size="sm"
                                            variant="subtle"
                                            color="green"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPreview(record);
                                            }}
                                            disabled={!record.url}
                                        >
                                            <IconEye size={16} />
                                        </ActionIcon>
                                        <ActionIcon size="sm" variant={expandedRecordIds.includes(record.id) ? 'light' : 'subtle'} color="blue">
                                            <IconEdit size={16} />
                                        </ActionIcon>
                                        <ActionIcon
                                            size="sm"
                                            variant="subtle"
                                            color="red.4"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeleteRecord(record);
                                            }}
                                        >
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Group>
                                ),
                            },
                        ]}
                        pinLastColumn
                        records={records}
                        fetching={loading}
                        selectedRecords={selectedRecords}
                        onSelectedRecordsChange={setSelectedRecords}
                        selectionTrigger="cell"
                        onScrollToBottom={() => {
                            if (records && lastVisible) {
                                loadMoreRecords(lastVisible);
                            }
                        }}
                        rowExpansion={{
                            expanded: {
                                recordIds: expandedRecordIds,
                                onRecordIdsChange: setExpandedRecordIds,
                            },
                            content: ({ record, collapse }) => (
                                <ClaimEditor
                                    initialData={record}
                                    onCancel={collapse}
                                    onSave={async (record) => {
                                        const ref = doc(db, Firebase.Firestore.Collection.Claims, record.id);
                                        try {
                                            await updateDoc(ref, record);

                                            if (records) {
                                                const index = records?.findIndex((r) => r.id === record.id);
                                                setRecords([...records.slice(0, index), record, ...records.slice(index + 1)]);
                                            }

                                            collapse();
                                        } catch (error) {
                                            capture(error);
                                        }
                                    }}
                                />
                            ),
                        }}
                        scrollViewportRef={scrollViewportRef}
                    />
                </Box>
            </Box>
            {previewRecord && (
                <Modal size="auto" transitionProps={{ duration: 200, transition: 'fade' }} centered title={previewRecord?.name} opened={showPreview} onClose={closePreview} fullScreen={isMobile}>
                    <Image src={previewRecord?.url} mah="75vh" />
                </Modal>
            )}
            <ConfirmationModal
                title="Delete claim record"
                message="Are you sure you want to delete this record? This action cannot be undone."
                opened={!!deleteRecord}
                onConfirm={async () => {
                    if (deleteRecord === null) {
                        setDeleteRecord(null);
                    }

                    if (deleteRecord) {
                        await deleteDoc(doc(db, Firebase.Firestore.Collection.Claims, deleteRecord.id))
                            .then(() => {
                                setRecords((records) => {
                                    if (records) {
                                        const index = records.findIndex((r) => r.id === deleteRecord.id);
                                        if (index >= 0) {
                                            records.splice(index, 1);
                                        }
                                    }

                                    return records;
                                });
                            })
                            .finally(() => {
                                setDeleteRecord(null);
                            });
                    }
                }}
                onClose={() => setDeleteRecord(null)}
                confirmButtonLabel="Delete"
                confirmButtonProps={{ color: 'red' }}
            />
        </Box>
    );
}
