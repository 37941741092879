import { useState } from 'react';
import { Anchor, Button, Checkbox, Divider, Group, LoadingOverlay, Paper, PaperProps, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { upperFirst, useToggle } from '@mantine/hooks';
import { GoogleButton } from '../GoogleButton/GoogleButton';

type FormType = 'login' | 'register';

interface FormValues {
    email: string;
    name: string;
    password: string;
    terms: boolean;
}

interface Form extends FormValues {
    type: FormType;
}

interface AuthenticationFormProps {
    onSubmit?: (form: Form) => Promise<void>;
    onGoogleLogin?: () => Promise<void>;
}

export function AuthenticationForm({ onSubmit, onGoogleLogin, ...props }: AuthenticationFormProps & PaperProps) {
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, toggle] = useToggle<FormType>(['login', 'register']);
    const form = useForm({
        initialValues: {
            email: '',
            name: '',
            password: '',
            terms: true,
        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <Paper radius="md" p="xl" withBorder pos="relative" {...props}>
            <Text size="lg" fw={500}>
                Welcome, {type} with
            </Text>

            <Group grow mb="md" mt="md">
                <GoogleButton
                    radius="sm"
                    onClick={async () => {
                        if (onGoogleLogin) {
                            setLoading(true);
                            await onGoogleLogin().finally(() => setLoading(false));
                        }
                    }}
                >
                    Google
                </GoogleButton>
            </Group>

            <Divider label="Or continue with email" labelPosition="center" my="lg" />

            <form
                onSubmit={form.onSubmit((values) => {
                    if (onSubmit) {
                        setSubmitting(true);
                        onSubmit({ ...values, type }).finally(() => setSubmitting(false));
                    }
                })}
            >
                <Stack>
                    {type === 'register' && (
                        <TextInput label="Name" placeholder="Your name" value={form.values.name} onChange={(event) => form.setFieldValue('name', event.currentTarget.value)} radius="md" />
                    )}

                    <TextInput
                        required
                        label="Email"
                        placeholder="hello@nk-solution.com"
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email && 'Invalid email'}
                        radius="sm"
                        disabled={submitting}
                    />

                    <PasswordInput
                        required
                        label="Password"
                        placeholder="Your password"
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                        error={form.errors.password && 'Password should include at least 6 characters'}
                        radius="sm"
                        disabled={submitting}
                    />

                    {type === 'register' && (
                        <Checkbox label="I accept terms and conditions" checked={form.values.terms} onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)} />
                    )}
                </Stack>

                <Group justify="space-between" mt="xl">
                    <Anchor component="button" type="button" c="dimmed" onClick={() => toggle()} size="xs">
                        {type === 'register' ? 'Already have an account? Login' : "Don't have an account? Register"}
                    </Anchor>
                    <Button type="submit" radius="sm" loading={submitting}>
                        {upperFirst(type)}
                    </Button>
                </Group>
            </form>
            <LoadingOverlay visible={loading} overlayProps={{ radius: 'sm', blur: 2 }} />
        </Paper>
    );
}
