import { DataTable } from 'mantine-datatable';

const records = [
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
    { name: 'Feest, Bogan and Herzog', missionStatement: 'Innovate bricks-and-clicks metrics.', stressAddress: '21716 Ratke Drive', state: 'Stromanport	WY' },
];

export function CustomDataTable() {
    return (
        <DataTable
            striped
            columns={[{ accessor: 'name' }, { accessor: 'missionStatement', width: 150 }, { accessor: 'streetAddress' }, { accessor: 'city' }, { accessor: 'state' }]}
            records={records}
        />
    );
}
