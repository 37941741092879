import * as Sentry from '@sentry/react';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import ReactDOM from 'react-dom/client';
import App from './App';

Sentry.init({
    dsn: 'https://37fae8448abbae277ef20c420d6b49ea@o4508343300718592.ingest.us.sentry.io/4508343314284544',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'nk-solution.com'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
});

polyfillCountryFlagEmojis();

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
