import { useMemo } from 'react';
import { Image, Modal, ModalProps } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';

type ImagePreviewModalProps = {
    file?: FileWithPath;
} & ModalProps;

export function ImagePreviewModal({ file, ...props }: ImagePreviewModalProps) {
    const isMobile = useMediaQuery('(max-width: 50em)');
    const imageUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);

    return (
        <Modal {...props} title={file?.name} size="auto" fullScreen={isMobile} centered transitionProps={{ duration: 200, transition: 'fade' }}>
            {imageUrl && <Image src={imageUrl} mah="75vh" />}
        </Modal>
    );
}
