import * as Sentry from '@sentry/react';
import { isAxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import { NotificationData, notifications } from '@mantine/notifications';
import classes from './error.module.css';

function getFirebaseAuthErrorMessage(errorCode: string): string {
    switch (errorCode) {
        case 'auth/invalid-email':
            return 'The email address is not valid. Please enter a valid email.';
        case 'auth/user-disabled':
            return 'This account has been disabled. Please contact support for assistance.';
        case 'auth/user-not-found':
            return 'No user found with this email. Please check your email and try again.';
        case 'auth/wrong-password':
            return 'Incorrect password. Please try again or reset your password.';
        case 'auth/invalid-credential':
            return 'Incorrect credential. Please try again.';
        case 'auth/email-already-in-use':
            return 'This email is already associated with an account. Please use a different email.';
        case 'auth/weak-password':
            return 'The password is too weak. Please use a stronger password.';
        case 'auth/operation-not-allowed':
            return 'This operation is not allowed. Please contact support for more information.';
        // Add more cases as needed
        default:
            return 'An unknown error occurred. Please try again later.';
    }
}

export function capture(error: unknown, override?: Partial<NotificationData>) {
    let title = 'Unknown Error';
    let message = 'An unknown error occurred. Please try again later.';

    if (error instanceof FirebaseError) {
        title = error.name;
        message = getFirebaseAuthErrorMessage(error.code);
    } else if (isAxiosError(error)) {
        title = error.name;
        message = error.response?.data?.message ?? error.message;
    } else if (error instanceof Error) {
        title = error.name;
        message = error.message;
    }

    // Sentry
    Sentry.captureException(error);

    // UI notification
    const notify = override?.id ? (data: NotificationData) => notifications.update({ ...data, ...override }) : (data: NotificationData) => notifications.show({ ...data, ...override });
    notify({
        color: 'red',
        title,
        message,
        classNames: classes,
    });

    // eslint-disable-next-line no-console
    console.error(error);
}
