import { useEffect, useState } from 'react';
import { IconArrowBackUp, IconCheck } from '@tabler/icons-react';
import { getDownloadURL, ref } from 'firebase/storage';
import { Button, Group, Image, SimpleGrid, Skeleton, Textarea, TextInput } from '@mantine/core';
import { DateInput, DateValue } from '@mantine/dates';
import { CurrencyInput } from '@/components/CurrencyInput/CurrencyInput';
import { storage } from '@/firebase';
import { Record } from '@/types/claim';
import classes from './ClaimEditor.module.css';

type Props = {
    initialData: Record;
    onSave?: (data: Record) => Promise<void>;
    onCancel?: () => void;
};

export const ClaimEditor: React.FC<Props> = ({ initialData, onSave, onCancel }) => {
    const [description, setDescription] = useState(initialData.description);
    const [category, setCategory] = useState(initialData.category);
    const [currencyCode, setCurrencyCode] = useState(initialData.currencyCode || 'HKD');
    const [amount, setAmount] = useState(initialData.amount);
    const [date, setDate] = useState<DateValue>(initialData.date && !isNaN(Date.parse(initialData.date)) ? new Date(initialData.date) : null);

    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getDownloadURL(ref(storage, initialData.url)).then((url) => {
            setPreviewUrl(url);
        });
    }, [initialData.url]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.preview}>
                <Image
                    src={previewUrl}
                    alt="Invoice"
                    hidden={!showPreview}
                    width={showPreview ? '100%' : 0}
                    height={showPreview ? '100%' : 0}
                    radius="sm"
                    onLoad={() => {
                        setShowPreview(true);
                    }}
                />
                {!showPreview && <Skeleton radius="sm" width="100%" height="100%" />}
            </div>

            <form
                className={classes.form}
                onSubmit={async (event) => {
                    event.preventDefault();
                    if (onSave) {
                        setSaving(true);
                        await onSave({ ...initialData, description, category, amount, currencyCode, date: date?.toLocaleDateString() }).finally(() => setSaving(false));
                    }
                }}
            >
                <div>
                    <Textarea label="Summary" placeholder="Please include all relevant information" value={description} onChange={(event) => setDescription(event.currentTarget.value)} required />

                    <TextInput mt="md" label="Category" placeholder="Category" value={category} onChange={(event) => setCategory(event.target.value)} required />

                    <SimpleGrid mt="md" cols={{ base: 1, sm: 2 }}>
                        <CurrencyInput label="Amount" value={{ currencyCode, amount }} onAmountChange={setAmount} onCurrencyChange={setCurrencyCode} required />
                        <DateInput label="Date" placeholder="Pick date and time" value={date} onChange={setDate} required />
                    </SimpleGrid>

                    <Group justify="center" mt="md">
                        <Button variant="default" size="xs" className={classes.control} leftSection={<IconArrowBackUp size={16} />} onClick={() => onCancel && onCancel()}>
                            Cancel
                        </Button>
                        <Button type="submit" loading={saving} className={classes.control} size="xs" leftSection={<IconCheck size={16} />}>
                            Save
                        </Button>
                    </Group>
                </div>
            </form>
        </div>
    );
};
