import { Button, ButtonProps, Loader } from '@mantine/core';
import classes from './CountingButton.module.css';

export function CountingButton(props: ButtonProps & React.ComponentPropsWithoutRef<'button'>) {
    const { loading, onClick, ...rest } = props;
    return (
        <Button
            {...rest}
            leftSection={loading ? <Loader size={20} className={classes.loader} /> : props.leftSection}
            radius="md"
            classNames={classes}
            onClick={(e) => {
                if (loading) {
                    e.stopPropagation();
                    e.preventDefault();
                } else {
                    onClick?.(e);
                }
            }}
        />
    );
}
