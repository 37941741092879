import { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, Firebase } from '@/firebase';

type Credit = {
    consumption: number;
};

function isCredit(value: unknown): value is Credit {
    return !!value && (value as Credit).consumption !== undefined && typeof (value as Credit).consumption === 'number';
}

export const useCredit = (user: User | null) => {
    const [credit, setCredit] = useState<Credit | undefined>(undefined);

    useEffect(() => {
        if (user) {
            const unsub = onSnapshot(doc(db, Firebase.Firestore.Collection.Credits, user.uid), (doc) => {
                const data = doc.data();
                if (isCredit(data)) {
                    setCredit(data);
                }
            });

            return () => unsub();
        }
    }, [user]);

    return { credit };
};
