import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile, User, UserCredential } from 'firebase/auth';
import { auth } from '../../firebase';

interface AuthContextType {
    currentUser: User | null;
    isAuthenticated: boolean | null;
    login: (email: string, password: string) => Promise<UserCredential>;
    logout: () => Promise<void>;
    signUp: (email: string, password: string, name?: string) => Promise<UserCredential>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            if (user) {
                setIsAuthenticated(true);
                // user.getIdToken().then((token) => {
                //     console.log(token);
                // });
            } else {
                setIsAuthenticated(false);
            }
        });

        return unsubscribe;
    }, []);

    const login = async (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logout = async () => {
        return signOut(auth);
    };

    const signUp = async (email: string, password: string, name?: string) => {
        return createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
            if (name) {
                await updateProfile(userCredential.user, { displayName: name });
            }
            return userCredential;
        });
    };

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                isAuthenticated,
                login,
                logout,
                signUp,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};
