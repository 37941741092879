import { Badge } from '@mantine/core';

const roleColors: Record<string, string> = {
    admin: 'red',
    dev: 'green',
    orgAdmin: 'violet',
    orgUser: 'blue',
};

export const RoleBadge = ({ role }: { role: string }) => {
    return (
        <Badge color={roleColors[role] || 'gray'} variant="light">
            {role}
        </Badge>
    );
};
