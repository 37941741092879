import { IconFileX } from '@tabler/icons-react';
import { Flex, Tooltip } from '@mantine/core';

interface MissingIconProps {
    size?: number;
}

export function MissingIcon({ size = 20 }: MissingIconProps) {
    return (
        <Tooltip arrowOffset={10} arrowSize={4} label="Missing" withArrow position="top-start">
            <Flex c="red.4">
                <IconFileX size={size} />
            </Flex>
        </Tooltip>
    );
}
