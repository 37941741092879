import { forwardRef } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import { User } from 'firebase/auth';
import { Avatar, Group, rem, Text, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import classes from './UserButton.module.css';

export type UserButtonProps = {
    user: User | null;
};

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(({ user, ...rest }: UserButtonProps & UnstyledButtonProps, ref) => (
    <UnstyledButton {...rest} className={classes.user} ref={ref}>
        <Group>
            <Avatar radius="xl" src={user?.photoURL} name={user?.displayName || undefined} color="initials" />

            <div style={{ flex: 1 }}>
                <Text size="sm" fw={500}>
                    {user?.displayName}
                </Text>

                <Text c="dimmed" size="xs">
                    {user?.email}
                </Text>
            </div>

            <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
        </Group>
    </UnstyledButton>
));
