/* tslint:disable */
/* eslint-disable */
/**
 * NK Solution Core API
 * OpenAPI backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AnalysisClaimsPost200Response
 */
export interface AnalysisClaimsPost200Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysisClaimsPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<AnalysisClaimsPost200ResponseClaimsInner>}
     * @memberof AnalysisClaimsPost200Response
     */
    'claims'?: Array<AnalysisClaimsPost200ResponseClaimsInner>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisClaimsPost200Response
     */
    'conclusion'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisClaimsPost200ResponseClaimsInner
 */
export interface AnalysisClaimsPost200ResponseClaimsInner {
    /**
     * 
     * @type {string}
     * @memberof AnalysisClaimsPost200ResponseClaimsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisClaimsPost200ResponseClaimsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisClaimsPost200ResponseClaimsInner
     */
    'analysis'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisClaimsPost200ResponseClaimsInner
     */
    'valid'?: boolean;
}
/**
 * 
 * @export
 * @interface AnalysisClaimsPostRequest
 */
export interface AnalysisClaimsPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisClaimsPostRequest
     */
    'claims'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnalysisInvoicesPost200Response
 */
export interface AnalysisInvoicesPost200Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisInvoicesPost200Response
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost200Response
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface AnalysisInvoicesPost400Response
 */
export interface AnalysisInvoicesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysisInvoicesPost400Response
     */
    'message'?: string;
}

/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisClaimsPost: async (analysisClaimsPostRequest: AnalysisClaimsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisClaimsPostRequest' is not null or undefined
            assertParamExists('analysisClaimsPost', 'analysisClaimsPostRequest', analysisClaimsPostRequest)
            const localVarPath = `/analysis/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisClaimsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisInvoicesPost: async (image: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('analysisInvoicesPost', 'image', image)
            const localVarPath = `/analysis/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisClaimsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisClaimsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisClaimsPost(analysisClaimsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnalysisApi.analysisClaimsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisInvoicesPost(image: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisInvoicesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysisInvoicesPost(image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnalysisApi.analysisInvoicesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisApiFp(configuration)
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisClaimsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnalysisClaimsPost200Response> {
            return localVarFp.analysisClaimsPost(analysisClaimsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisInvoicesPost(image: File, options?: RawAxiosRequestConfig): AxiosPromise<AnalysisInvoicesPost200Response> {
            return localVarFp.analysisInvoicesPost(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
    /**
     * 
     * @summary Analyse expense claims validity
     * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public analysisClaimsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig) {
        return AnalysisApiFp(this.configuration).analysisClaimsPost(analysisClaimsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analyse an invoice image
     * @param {File} image Image file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public analysisInvoicesPost(image: File, options?: RawAxiosRequestConfig) {
        return AnalysisApiFp(this.configuration).analysisInvoicesPost(image, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExpenseApi - axios parameter creator
 * @export
 */
export const ExpenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseClaimsPost: async (image: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('expenseClaimsPost', 'image', image)
            const localVarPath = `/expense/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export invoices
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseExportsPost: async (analysisClaimsPostRequest: AnalysisClaimsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysisClaimsPostRequest' is not null or undefined
            assertParamExists('expenseExportsPost', 'analysisClaimsPostRequest', analysisClaimsPostRequest)
            const localVarPath = `/expense/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisClaimsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpenseApi - functional programming interface
 * @export
 */
export const ExpenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseClaimsPost(image: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisInvoicesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseClaimsPost(image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpenseApi.expenseClaimsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export invoices
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expenseExportsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisInvoicesPost400Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expenseExportsPost(analysisClaimsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpenseApi.expenseExportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExpenseApi - factory interface
 * @export
 */
export const ExpenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseClaimsPost(image: File, options?: RawAxiosRequestConfig): AxiosPromise<AnalysisInvoicesPost200Response> {
            return localVarFp.expenseClaimsPost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export invoices
         * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expenseExportsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnalysisInvoicesPost400Response> {
            return localVarFp.expenseExportsPost(analysisClaimsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpenseApi - object-oriented interface
 * @export
 * @class ExpenseApi
 * @extends {BaseAPI}
 */
export class ExpenseApi extends BaseAPI {
    /**
     * 
     * @summary Upload an invoice image to create an expense claim
     * @param {File} image Image file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseClaimsPost(image: File, options?: RawAxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseClaimsPost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export invoices
     * @param {AnalysisClaimsPostRequest} analysisClaimsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expenseExportsPost(analysisClaimsPostRequest: AnalysisClaimsPostRequest, options?: RawAxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expenseExportsPost(analysisClaimsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



