// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { NothingFoundBackground } from '@/components/NothingFoundBackground/NothingFoundBackground';
import { useAuth } from '../providers/auth/AuthProvider';

interface DevRouteProps {}

const DevRoute: React.FC<DevRouteProps> = () => {
    const { currentUser } = useAuth();
    const [enabled, setEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then((idTokenResult) => {
                if (Array.isArray(idTokenResult.claims.roles) && idTokenResult.claims.roles.includes('dev')) {
                    setEnabled(true);
                }
            });
        }
    }, [currentUser]);

    return enabled ? <Outlet /> : <NothingFoundBackground />;
};

export default DevRoute;
